import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import type { TimeRangePickerProps } from "antd";
import { DatePicker, Select, Space } from "antd";
import { useQuery } from "react-query";
import { getAllOutlet } from "../../../services/categories";
import { useFilterStore } from "../../../store/dashboardFilter";
import { profileStore } from "../../../store/profile";

const { RangePicker } = DatePicker;

const rangePresets: TimeRangePickerProps["presets"] = [
  {
    label: "Hari Ini",
    value: [dayjs().startOf("day"), dayjs().endOf("day")],
  },
  {
    label: "Kemarin",
    value: [
      dayjs().add(-1, "d").startOf("day"),
      dayjs().add(-1, "d").endOf("day"),
    ],
  },
  {
    label: "7 Hari Terakhir",
    value: [dayjs().add(-7, "d"), dayjs().endOf("day")],
  },
  {
    label: "14 Hari Terakhir",
    value: [dayjs().add(-14, "d"), dayjs().endOf("day")],
  },
  {
    label: "30 Hari Terakhir",
    value: [dayjs().add(-30, "d"), dayjs().endOf("day")],
  },
  {
    label: "90 Hari Terakhir",
    value: [dayjs().add(-90, "d"), dayjs().endOf("day")],
  },
];

const Filter = ({
  onRangeChange,
}: {
  onRangeChange: (dates: null | (Dayjs | null)[]) => void;
}) => {
  const { data: outletList, isLoading } = useQuery<{
    data: { outlet_id: string; name: string }[];
  }>("outletList", getAllOutlet);
  const { setFilterByOutlet } = useFilterStore((state) => state);
  const { outletId } = profileStore((state) => state);

  const listOutlet = outletList?.data.map((item) => ({
    label: item.name,
    value: item.outlet_id,
  }));

  return (
    <Space direction="horizontal" className="my-4">
      <RangePicker
        presets={rangePresets}
        format={"DD/MM/YYYY"}
        onChange={onRangeChange}
        changeOnBlur
        disabledDate={(current) => current && current > dayjs().endOf("day")}
        defaultValue={[dayjs().startOf("day"), dayjs().endOf("day")]}
      />
      <Select
        defaultValue={outletId}
        loading={isLoading}
        showSearch
        style={{ width: 200 }}
        placeholder="Filter by outlet"
        options={[
          { label: "Semua Outlet", value: "all" },
          ...(listOutlet ?? []),
        ]}
        onChange={(value) => setFilterByOutlet(value)}
      />
    </Space>
  );
};

export default Filter;
