import { Navigate } from "react-router-dom";
import { profileStore } from "../../../store/profile";

interface PrivateRouteProps {
  component: JSX.Element;
  authorizedRoles: string;
}

const PrivateRoute = ({
  authorizedRoles,
  component: Component,
}: PrivateRouteProps) => {
  const { roles: userRole } = profileStore((state) => state);

  if (userRole.toLowerCase() !== authorizedRoles.toLowerCase())
    return (
      <Navigate to={userRole.toLowerCase() === "admin" ? "/reporting" : "/"} />
    );

  return Component;
};

export default PrivateRoute;
