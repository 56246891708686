import axios from "./axiosInstance";

interface PayloadMenu {
  name: string;
  description: string;
  price: number;
  discount_price: number;
  category_id: string;
}

export const getMenuByOutletId = async (outletId: string) => {
  const response = await axios.get(`/v1/menu/${outletId}`);
  return response.data;
};

export const getMenuList = async (params: {
  outletId: string;
  page: number;
  limit: number;
}) => {
  const response = await axios.get(
    `/v1/menu/only/${params.outletId}?page=${params.page}&limit=${params.limit}`
  );
  return response.data;
};

export const addNewMenu = async ({
  outletId,
  payload,
}: {
  outletId: string;
  payload: PayloadMenu;
}) => {
  const response = await axios.post(`/v1/menu/${outletId}`, payload);
  return response.data;
};

export const updateMenu = async ({
  menuId,
  payload,
}: {
  menuId: string;
  payload: PayloadMenu;
}) => {
  const response = await axios.put(`/v1/menu/${menuId}`, payload);
  return response.data;
};

export const deleteMenuById = async ({ menuId }: { menuId: string }) => {
  const response = await axios.delete(`/v1/menu/${menuId}`);
  return response.data;
};
