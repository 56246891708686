import { Divider } from "antd";
import { InvoiceDetailProps } from "../../types/global";
import dayjs from "dayjs";
import { numberToRupiah } from "../../utils/normalize";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(timezone);
dayjs.extend(utc);

dayjs.tz.setDefault("Asia/Jakarta");

export default function Invoice({
  data,
}: {
  data: InvoiceDetailProps | undefined;
}) {
  if (!data) return null;

  return (
    <>
      <div className="max-w-xs mx-auto border p-6">
        <h1 className="text-center">{data?.outlet.name}</h1>
        <Divider dashed className="border-black my-4" />
        <div className="flex justify-between">
          <p>Order ID</p>
          <p className="text-right">{data?.transaction_id}</p>
        </div>
        <div className="flex justify-between">
          <p>Tanggal</p>
          <p>{dayjs(data?.createdAt).format("DD/MM/YYYY")}</p>
        </div>
        <div className="flex justify-between">
          <p>Waktu</p>
          <p>{dayjs(data?.createdAt).format("HH:mm")}</p>
        </div>

        <Divider dashed className="border-black my-4" />
        <table className="w-full">
          <thead>
            <tr>
              <th>QTY</th>
              <th>ITEM</th>
              <th align="right">AMT</th>
            </tr>
          </thead>

          <tbody>
            {data?.orders.map((order, index) => (
              <tr key={order.menu.name}>
                <td>{order.quantity}</td>
                <td align="left">{order.menu.name}</td>
                <td align="right">
                  {data?.isDiscount
                    ? numberToRupiah(order.menu.discount_price)
                    : numberToRupiah(order.menu.price)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Divider dashed className="border-black my-4" />
        <div className="flex justify-between font-semibold">
          <p>Total</p>
          <p>{numberToRupiah(data?.totalPrice)}</p>
        </div>
        <div className="flex justify-between">
          <p>Bayar</p>
          <p>{numberToRupiah(data?.cash)}</p>
        </div>
        <div className="flex justify-between">
          <p>Kembali</p>
          <p>{numberToRupiah(data?.change)}</p>
        </div>
      </div>
    </>
  );
}
