import {
  Alert,
  Button,
  Divider,
  Empty,
  Form,
  InputNumber,
  Modal,
  Radio,
  Typography,
  message,
} from "antd";
import dayjs from "dayjs";
import CartItems from "./CartItems";
import useCartStore from "../../../store/cart";
import { numberToRupiah } from "../../../utils/normalize";
import { useState } from "react";
import { useQuery } from "react-query";
import { getPaymentMethod } from "../../../services/transactions";
import { PaymentMethodProps, TransactionProps } from "../../../types/global";
import { profileStore } from "../../../store/profile";
import type { UseMutateFunction } from "react-query";
const { Text } = Typography;

export default function Cart({
  mutate,
}: {
  mutate: UseMutateFunction<unknown, unknown, TransactionProps, unknown>;
}) {
  const [form] = Form.useForm();
  const [paymentMethodList, setPaymentMethodList] = useState<
    PaymentMethodProps[]
  >([]);
  const { clearCart, items, isDiscount } = useCartStore((state) => state);
  const { outletId, userId } = profileStore((state) => state);
  const inputValues = Form.useWatch([], form);

  const calculateTotalPrice = () => {
    return items.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
  };

  const calculateKembalian = () => {
    if (!inputValues?.nominal) return 0;
    if (inputValues?.nominal < calculateTotalPrice()) return 0;
    return inputValues?.nominal - calculateTotalPrice();
  };

  const nominal = inputValues?.nominal || "0";

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const payload = {
          outlet_id: outletId,
          user_id: userId,
          payment_method_id: values.payment,
          totalPrice: calculateTotalPrice(),
          isDiscount: isDiscount,
          cash: values.nominal,
          change: calculateKembalian() || 0,
          menus: items.map((item) => ({
            menu_id: item.id,
            quantity: item.quantity,
          })),
        };
        mutate(payload);
        form.resetFields();
      })
      .catch(() => {
        return;
      });
  };

  const confirmTransaction = () => {
    Modal.confirm({
      title: "Yakin proses transaksi ini?",
      okText: "Proses Bayar",
      cancelText: "Tidak",
      okButtonProps: {
        className: "bg-green-600 hover:!bg-green-500",
      },
      centered: true,
      onOk: () => {
        clearCart();
        handleSubmit();
      },
    });
  };

  const { isLoading } = useQuery("getPaymentMethod", getPaymentMethod, {
    onSuccess: (response: { data: PaymentMethodProps[] }) => {
      setPaymentMethodList(response?.data);
    },
    onError: () => {
      message.error("Gagal memuat data metode pembayaran");
    },
  });

  return (
    <>
      <Form layout="vertical" form={form}>
        <div className="flex flex-col tracking-wide gap-2">
          <Text className="font-semibold text-2xl">Order</Text>
          <Text className="text-gray-400">
            {dayjs().format("dddd, HH:mm A DD/MM/YY")}
          </Text>
        </div>
        <div className="h-[320px] overflow-x-hidden mt-4">
          <div className="flex flex-col tracking-wide gap-2">
            {items.length === 0 ? (
              <Empty description="Keranjang Kosong" className="mt-8" />
            ) : (
              <>
                {items.map((cart) => (
                  <CartItems
                    id={cart.id}
                    key={cart.id}
                    name={cart.name}
                    quantity={cart.quantity}
                    price={cart.price}
                  />
                ))}
              </>
            )}
          </div>
        </div>
        <Divider />
        {!isLoading && (
          <Form.Item
            name="payment"
            className="mt-4"
            label={<Text className="font-semibold">Metode pembayaran</Text>}
          >
            <Radio.Group
              size="large"
              buttonStyle="solid"
              disabled={items.length === 0}
            >
              {paymentMethodList?.map((payment) => (
                <Radio.Button
                  value={payment.payment_method_id}
                  key={payment.payment_method_id}
                >
                  {payment.name}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        )}
        {inputValues?.payment && items.length !== 0 && (
          <Alert
            message={<Text className="font-semibold text-lg">Nominal</Text>}
            description={
              <>
                <Form.Item name="nominal">
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="nominal"
                    size="large"
                    prefix="Rp"
                    controls={false}
                    onChange={(value) => {
                      form.setFieldsValue({ presets: null });
                    }}
                    type="number"
                    min={1}
                    maxLength={10}
                  />
                </Form.Item>
                <Form.Item name="presets" noStyle>
                  <Radio.Group
                    buttonStyle="solid"
                    onChange={(e) => {
                      form.setFieldsValue({ nominal: e.target.value });
                    }}
                  >
                    <Radio.Button value={calculateTotalPrice()}>
                      Uang Pas
                    </Radio.Button>
                    <Radio.Button value={50000}>Rp 50.000</Radio.Button>
                    <Radio.Button value={100000}>Rp 100.000</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </>
            }
            type="info"
          />
        )}

        <Alert
          className="mt-4"
          message="Transfer"
          description={
            <div className="flex justify-between items-center">
              <Text className="font-semibold text-lg">
                No Rek: {process.env.REACT_APP_NO_REKENING}
              </Text>
            </div>
          }
          type="info"
        />
      </Form>
      <Divider />
      <div className="px-2">
        <div className="flex justify-between items-center mt-2">
          <Text className="font-semibold text-lg">Total</Text>
          <Text className="font-semibold text-lg">
            {numberToRupiah(calculateTotalPrice())}
          </Text>
        </div>
        <div className="flex justify-between items-center mt-2">
          <Text className="font-semibold text-lg">Cash</Text>
          <Text className="font-semibold text-lg">
            {numberToRupiah(nominal)}
          </Text>
        </div>
        <div className="flex justify-between items-center mt-2">
          <Text className="font-semibold text-lg">Kembalian</Text>
          <Text className="font-semibold text-lg">
            {numberToRupiah(calculateKembalian())}
          </Text>
        </div>
      </div>
      <Button
        type="primary"
        className="bg-blue-500 mt-6"
        block
        size="large"
        disabled={
          items.length === 0 ||
          !inputValues?.payment ||
          !inputValues?.nominal ||
          inputValues?.nominal < calculateTotalPrice()
        }
        onClick={confirmTransaction}
      >
        Lanjutkan
      </Button>
    </>
  );
}
