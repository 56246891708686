import { create } from "zustand";

type FilterState = {
  filterByOutlet: string | null;
  setFilterByOutlet: (outletId: string | null) => void;
};

export const useFilterStore = create<FilterState>((set) => ({
  filterByOutlet: null,
  setFilterByOutlet: (outletId) =>
    set(() => ({
      filterByOutlet: outletId,
    })),
}));
