import axios from "./axiosInstance";

export const login = async ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => {
  const response = await axios.post("/v1/auth/login", { username, password });
  return response.data;
};
