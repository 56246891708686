import { PrinterOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Row,
  Space,
  Statistic,
  message,
  Typography,
} from "antd";
import CustomTable from "../components/Global/CustomTable";
import {
  getSummaryTransaction,
  getTransactionList,
  getTotalPerItem,
} from "../services/transactions";
import { profileStore } from "../store/profile";
import { useQuery } from "react-query";
import usePagination from "../hooks/usePagination";
import { useState } from "react";
import { InvoiceDetailProps } from "../types/global";
import { numberToRupiah } from "../utils/normalize";
import Filter from "../components/Reporting/Filter";
import dayjs, { Dayjs } from "dayjs";
import { useNavigate } from "react-router-dom";
import PrivateComponent from "../components/Global/PrivateComponent";
import { useFilterStore } from "../store/dashboardFilter";

const { Title } = Typography;

export default function ReportingDashboard() {
  const { outletId, roles } = profileStore((state) => state);
  const [list, setList] = useState<InvoiceDetailProps[]>([]);
  const [filter, setFilter] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: dayjs().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    endDate: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
  });
  const [summary, setSummary] = useState<{
    totalIncome: string;
    totalOrder: number;
    cashIncome: string;
    qrisIncome: string;
    transferIncome: string;
  }>();
  const { filterByOutlet } = useFilterStore((state) => state);
  const navigate = useNavigate();

  const {
    setTotalCount,
    onChangeCurrentPage,
    onChangePageSize,
    pageSize,
    currentPage,
    totalCount,
  } = usePagination();

  const { isLoading } = useQuery({
    queryKey: [
      "getHistory",
      currentPage,
      outletId,
      pageSize,
      filter.startDate,
      filter.endDate,
      filterByOutlet,
    ],
    queryFn: () =>
      getTransactionList({
        outletId: filterByOutlet ?? outletId,
        params: {
          page: currentPage,
          limit: pageSize,
          start_date: filter.startDate,
          end_date: filter.endDate,
        },
      }),
    onSuccess: (data) => {
      setList(data?.data);
      setTotalCount(data?.count);
    },
    onError: () => {
      message.error("Unknown error");
    },
  });

  const { isLoading: loadingSummary } = useQuery({
    queryKey: [
      "getSummary",
      outletId,
      filter.startDate,
      filter.endDate,
      filterByOutlet,
    ],
    queryFn: () =>
      getSummaryTransaction({
        outletId: filterByOutlet ?? outletId,
        params: {
          start_date: filter.startDate,
          end_date: filter.endDate,
        },
      }),
    onSuccess: (data) => {
      setSummary(data?.data);
    },
    onError: () => {
      message.error("Gagal mendapatkan data summary");
    },
  });

  const onRangeChange = (dates: null | (Dayjs | null)[]) => {
    if (!dates) return;

    setFilter({
      startDate: dates[0]?.startOf("day").format("YYYY-MM-DD HH:mm:ss") ?? "",
      endDate: dates[1]?.endOf("day").format("YYYY-MM-DD HH:mm:ss") ?? "",
    });
  };

  const { data: totalPerItem, isLoading: loadingItemSold } = useQuery<{
    data: { totalSold: number; menu: { name: string } }[];
  }>({
    queryKey: [
      "getTotalPerItem",
      outletId,
      filter.startDate,
      filter.endDate,
      filterByOutlet,
    ],
    queryFn: () =>
      getTotalPerItem({
        outletId: filterByOutlet ?? outletId,
        params: {
          start_date: filter.startDate,
          end_date: filter.endDate,
        },
      }),
  });

  const TableReloading = isLoading || loadingSummary || loadingItemSold;

  const soldItems =
    totalPerItem?.data.map((item) => ({
      name: item.menu.name,
      totalSold: item.totalSold,
    })) ?? [];

  return (
    <>
      <Title level={5}>
        Detail transaksi periode{" "}
        {dayjs(filter.startDate).format("DD MMMM YYYY")} -{" "}
        {dayjs(filter.endDate).format("DD MMMM YYYY")}
      </Title>
      {!TableReloading && summary && (
        <Row gutter={16} className="my-8">
          <Col xs={14} sm={8}>
            <Card className="drop-shadow-lg">
              <Statistic
                title={<p className="font-bold">Total penjualan</p>}
                value={summary?.totalIncome ?? 0}
                valueStyle={{ color: "#3f8600" }}
                prefix="Rp."
              />
            </Card>
            <Card className="drop-shadow-lg mt-4">
              <Statistic
                title={<p className="font-semibold">Tunai</p>}
                value={summary?.cashIncome ?? 0}
                valueStyle={{ color: "#a0c239" }}
                prefix="Rp."
              />
              <Statistic
                className="mt-4"
                title={<p className="font-semibold">QRIS</p>}
                value={summary?.qrisIncome ?? 0}
                valueStyle={{ color: "#a0c239" }}
                prefix="Rp."
              />
              <Statistic
                className="mt-4"
                title={<p className="font-semibold">Transfer</p>}
                value={summary?.transferIncome ?? 0}
                valueStyle={{ color: "#a0c239" }}
                prefix="Rp."
              />
            </Card>
          </Col>
          <Col xs={10} sm={8}>
            <Card className="drop-shadow-lg">
              <Statistic
                title={<p className="font-bold">Jumlah Transaksi</p>}
                value={summary?.totalOrder}
              />
            </Card>
          </Col>
        </Row>
      )}
      <PrivateComponent authorizedRole={["admin", "investor"]} userRole={roles}>
        <Filter onRangeChange={onRangeChange} />
      </PrivateComponent>
      <CustomTable
        loading={TableReloading}
        columns={[
          {
            title: "InvoiceID",
            dataIndex: "transaction_id",
            key: "transaction_id",
            width: "15%",
          },
          {
            title: "Tanggal",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (date: string) => {
              return dayjs(date).local().format("DD MMMM YYYY HH:mm:ss");
            },
          },
          {
            title: "Pembayaran",
            dataIndex: "payment_method",
            key: "payment_method",
            responsive: ["md"],
            render: (payment_method: { name: string }) => {
              return payment_method.name;
            },
          },
          {
            title: "Diskon",
            dataIndex: "isDiscount",
            key: "isDiscount",
            responsive: ["md"],
            render: (isDiscount: boolean) => {
              return isDiscount ? "Ya" : "Tidak";
            },
          },
          {
            title: "Total",
            dataIndex: "totalPrice",
            key: "totalPrice",
            render: (price: number) => {
              return numberToRupiah(price);
            },
          },
          {
            title: "Menu",
            dataIndex: "orders",
            key: "orders",
            responsive: ["md"],
            render: (menus: { menu: { name: string }; quantity: number }[]) => {
              return (
                <>
                  {menus.map((menu) => (
                    <div key={menu.menu.name}>
                      <p>
                        {menu.menu.name} - {menu.quantity}
                      </p>
                    </div>
                  ))}
                </>
              );
            },
          },
          {
            title: "Outlet",
            dataIndex: "outlet",
            key: "outlet",
            responsive: ["md"],
            render: (outlet: { name: string }) => {
              return outlet.name;
            },
          },
          {
            title: "",
            key: "action",
            width: "10%",
            render: (_, { transaction_id }) => (
              <Space size="middle">
                <Button
                  type="default"
                  icon={<PrinterOutlined />}
                  onClick={() => navigate(`/result/${transaction_id}`)}
                  size="large"
                />
              </Space>
            ),
          },
        ]}
        dataSource={list}
        bordered
        pagination={{
          current: currentPage,
          total: totalCount,
          onChange: onChangeCurrentPage,
          onShowSizeChange: onChangePageSize,
        }}
      />

      <Title level={4} className="!my-6">
        Detail penjualan per menu
      </Title>
      <Row gutter={16} className="my-8">
        <Col sm={12}>
          <CustomTable
            loading={TableReloading}
            columns={[
              {
                title: "Nama menu",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "Total terjual",
                dataIndex: "totalSold",
                key: "totalSold",
              },
            ]}
            dataSource={soldItems}
            pagination={false}
            bordered
          />
        </Col>
      </Row>
    </>
  );
}
