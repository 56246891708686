import axios from "axios";
import { local } from "../utils/localStorage";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const axiosInstance = axios.create({
  baseURL: baseUrl,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = local.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      local.removeItem("token");
      local.removeItem("user-profile");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
