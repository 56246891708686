import { create } from "zustand";
import { ProfileStore } from "../types/global";
import { persist } from "zustand/middleware";

export const profileStore = create<ProfileStore>()(
  persist(
    (set) => ({
      outletId: "",
      outletName: "",
      userId: "",
      userName: "",
      roles: "",
      isAuth: false,
      setOutletName: (outletName) => set(() => ({ outletName })),
      setOutletId: (outletId) => set(() => ({ outletId })),
      setUserId: (userId) => set(() => ({ userId })),
      setUserName: (userName) => set(() => ({ userName })),
      setRoles: (roles) => set(() => ({ roles })),
      setIsAuth: (isAuth) => set(() => ({ isAuth })),
    }),
    {
      name: "user-profile",
    }
  )
);
