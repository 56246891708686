import ManagementMenu from "../components/ManagementMenu";
import { Button } from "antd";
import useModal from "../hooks/useModal";
import { useState, lazy, Suspense } from "react";
import { MenuType } from "../components/ManagementMenu/menu.interfaces";

const AddMenuModal = lazy(
  () => import("../components/ManagementMenu/AddMenuModal")
);
const EditMenuModal = lazy(
  () => import("../components/ManagementMenu/EditMenuModal")
);

export default function MenuDashboard() {
  const [selectedData, setSelectedData] = useState({} as MenuType);
  const addMenuModal = useModal();
  const editMenuModal = useModal();

  const handleOpenEditModal = (record: MenuType) => {
    setSelectedData(record);
    editMenuModal.openModal();
  };

  return (
    <>
      <Button
        type="primary"
        className="bg-blue-500 mb-2 mr-4"
        onClick={addMenuModal.openModal}
      >
        Tambah Menu
      </Button>
      <ManagementMenu openEditModal={handleOpenEditModal} />
      <Suspense fallback={<div>Loading...</div>}>
        <AddMenuModal
          isVisible={addMenuModal.visible}
          closeModal={addMenuModal.closeModal}
        />
        <EditMenuModal
          record={selectedData}
          isVisible={editMenuModal.visible}
          closeModal={editMenuModal.closeModal}
        />
      </Suspense>
    </>
  );
}
