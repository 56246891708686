import { useState } from "react";
import { Button, Modal, Select, Space, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteMenuById, getMenuList } from "../../services/menu";
import { profileStore } from "../../store/profile";
import { MenuType } from "./menu.interfaces";
import { numberToRupiah } from "../../utils/normalize";
import CustomTable from "../Global/CustomTable";
import usePagination from "../../hooks/usePagination";
import { getAllOutlet } from "../../services/categories";
import { useFilterStore } from "../../store/dashboardFilter";

const ManagementMenu = ({
  openEditModal,
}: {
  openEditModal: (record: MenuType) => void;
}) => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(deleteMenuById, {
    onSuccess: () => {
      message.success(`Sukses hapus menu`);
      queryClient.invalidateQueries("getListMenu");
    },
    onError: () => {
      message.error("Unknown error");
    },
  });

  const [modal, contextHolder] = Modal.useModal();
  const {
    currentPage,
    totalCount,
    pageSize,
    setTotalCount,
    onChangeCurrentPage,
    setCurrentPage,
    onChangePageSize,
  } = usePagination();

  const confirmDelete = (record: MenuType) => {
    modal.confirm({
      title: "Apakah anda yakin ingin menghapus menu ini?",
      content: "Menu yang dihapus tidak dapat dikembalikan",
      okText: "Ya, Hapus",
      cancelText: "Tidak",
      okButtonProps: {
        danger: true,
      },
      onOk: () => {
        mutate({ menuId: record?.menu_id });
      },
    });
  };

  const columns: ColumnsType<MenuType> = [
    {
      title: "Menu",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Harga",
      dataIndex: "price",
      key: "price",
      render: (_, { price }) => {
        return <span>{numberToRupiah(price)}</span>;
      },
    },
    {
      title: "Harga Diskon",
      dataIndex: "discount_price",
      key: "discount_price",
      responsive: ["md"],
      render: (_, { discount_price }) => {
        return <span>{numberToRupiah(discount_price)}</span>;
      },
    },
    {
      title: "Kategori",
      dataIndex: "category",
      key: "category",
      responsive: ["md"],
      render: (_, { category }) => {
        return <span>{category?.name}</span>;
      },
    },
    {
      title: "",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="default"
            icon={<EditOutlined />}
            size="small"
            onClick={() => openEditModal(record)}
          />
          <Button
            type="default"
            icon={<DeleteOutlined />}
            danger
            size="small"
            onClick={() => confirmDelete(record)}
          />
        </Space>
      ),
    },
  ];

  const outletId = profileStore((state) => state.outletId);
  const [data, setData] = useState<MenuType[]>([]);
  const { setFilterByOutlet, filterByOutlet } = useFilterStore(
    (state) => state
  );

  const { isLoading } = useQuery({
    queryKey: ["getListMenu", currentPage, pageSize, filterByOutlet],
    queryFn: () =>
      getMenuList({
        outletId: filterByOutlet ?? outletId,
        page: currentPage,
        limit: pageSize,
      }),
    onSuccess: (data) => {
      setTotalCount(data?.data?.count);
      setCurrentPage(data?.data?.currentPage);
      setData(data?.data?.items);
    },
  });

  const { data: outletList, isLoading: loadingOutlet } = useQuery<{
    data: { outlet_id: string; name: string }[];
  }>("outletList", getAllOutlet);

  return (
    <>
      <Space direction="horizontal" className="my-4">
        <Select
          defaultValue={outletId}
          loading={loadingOutlet}
          showSearch
          style={{ width: 200 }}
          placeholder="Filter by outlet"
          options={outletList?.data.map((outlet) => ({
            value: outlet.outlet_id,
            label: outlet.name,
          }))}
          onChange={(value) => setFilterByOutlet(value)}
        />
      </Space>
      <CustomTable
        title={() => "Menu"}
        columns={columns}
        bordered
        pagination={{
          pageSize: pageSize,
          total: totalCount,
          current: currentPage,
          onChange: onChangeCurrentPage,
          onShowSizeChange: onChangePageSize,
          showSizeChanger: true,
        }}
        dataSource={data}
        loading={isLoading}
      />

      {contextHolder}
    </>
  );
};

export default ManagementMenu;
