import { create } from "zustand";

export type CartItem = {
  id: string;
  name: string;
  price: number;
  quantity: number;
};

type CartStore = {
  items: CartItem[];
  isDiscount: boolean;
  setDiscount: (checked: boolean) => void;
  addItem: (item: CartItem) => void;
  removeItem: (id: string) => void;
  increaseQuantity: (id: string) => void;
  decreaseQuantity: (id: string) => void;
  clearCart: () => void;
};

const useCartStore = create<CartStore>((set) => ({
  items: [],
  isDiscount: false,
  addItem: (item) =>
    set((state) => {
      const itemIndex = state.items.findIndex(
        (cartItem) => cartItem.id === item.id
      );
      if (itemIndex !== -1) {
        const updatedItems = [...state.items];
        updatedItems[itemIndex].quantity += item.quantity;
        return { items: updatedItems };
      } else {
        return { items: [...state.items, item] };
      }
    }),
  removeItem: (id) =>
    set((state) => ({
      items: state.items.filter((item) => item.id !== id),
    })),
  increaseQuantity: (id) =>
    set((state) => ({
      items: state.items.map((item) =>
        item.id === id ? { ...item, quantity: item.quantity + 1 } : item
      ),
    })),
  decreaseQuantity: (id) =>
    set((state) => ({
      items: state.items.map((item) =>
        item.id === id && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      ),
    })),
  setDiscount: () =>
    set((state) => ({
      isDiscount: !state.isDiscount,
    })),
  clearCart: () =>
    set(() => ({
      items: [],
    })),
}));

export default useCartStore;
