import { Col, Row, Spin, message } from "antd";
import ListMenu from "../components/Transaction/ListMenu";
import Cart from "../components/Transaction/Cart/Cart";
import { useMutation } from "react-query";
import { InitiatePayment } from "../services/transactions";
import { useNavigate } from "react-router-dom";

export default function MainDashboard() {
  const navigate = useNavigate();

  const { mutate, isLoading: submitLoading } = useMutation(InitiatePayment, {
    onSuccess: (response) => {
      navigate(`/result/${response?.data?.invoiceId}`, { replace: true });
    },
    onError: () => {
      message.error("Transaksi gagal. Silahkan coba lagi");
    },
  });

  return (
    <Spin spinning={submitLoading}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} md={24} xl={16}>
          <ListMenu />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={8}>
          <div className="border-2 rounded-lg p-4">
            <Cart mutate={mutate} />
          </div>
        </Col>
      </Row>
    </Spin>
  );
}
