import axios from "./axiosInstance";

export const getCategories = async () => {
  const response = await axios.get(`/v1/categories`);
  return response.data;
};

export const getAllOutlet = async () => {
  const response = await axios.get(`/v1/outlet`);
  return response.data;
};
