import { useQuery } from "react-query";
import { Alert, Button, Card, Col, Row, Switch, Tabs } from "antd";
import { getMenuByOutletId } from "../../../services/menu";
import { useState } from "react";
import { profileStore } from "../../../store/profile";
import { DataType } from "../../ManagementMenu/menu.interfaces";
import useCartStore from "../../../store/cart";
import { numberToRupiah } from "../../../utils/normalize";
import { useWindowScroll } from "@uidotdev/usehooks";
import Marquee from "react-fast-marquee";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import classNames from "classnames";
dayjs.extend(isBetween);

const { Meta } = Card;

export default function ListMenu() {
  const [data, setData] = useState<DataType[]>([]);
  const outletId = profileStore((state) => state.outletId);
  const { isDiscount, setDiscount, addItem, clearCart } = useCartStore(
    (state) => state
  );

  const { isLoading } = useQuery({
    queryKey: ["getMenuByOutletId"],
    queryFn: () => getMenuByOutletId(outletId),
    onSuccess: (data) => {
      setData(data.data);
    },
  });

  const [{ x, y }, scrollTo] = useWindowScroll();

  const tabList = data.map((item) => {
    const tabClass = classNames({
      "text-yellow-500": item.name === "Makanan",
      "text-green-500": item.name !== "Makanan",
    });
    return {
      key: item.name,
      label: <p className={tabClass}>{item.name}</p>,
      children: (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-4">
          {item.menus.map((menu) => {
            const btnCardClass = classNames({
              "bg-red-500": menu.name === "Pengantaran",
              "bg-blue-500": menu.name !== "Pengantaran",
            });

            const cardClass = classNames("px-2 border-2", {
              "border-red-500": menu.name === "Pengantaran",
            });

            return (
              <Col xs={12} sm={12} md={8} key={menu.menu_id} className="mb-4">
                <Card
                  className={cardClass}
                  bordered={false}
                  actions={[
                    <Button
                      type="primary"
                      block
                      key={menu.name}
                      className={btnCardClass}
                      onClick={() => {
                        scrollTo({ left: 0, top: 3000, behavior: "smooth" });
                        addItem({
                          id: menu.menu_id,
                          name: menu.name,
                          price: isDiscount ? menu.discount_price : menu.price,
                          quantity: 1,
                        });
                      }}
                    >
                      Tambah
                    </Button>,
                  ]}
                >
                  <Meta
                    title={
                      <div className="whitespace-normal h-20">{menu.name}</div>
                    }
                    description={
                      <>
                        <div
                          className={`font-bold ${
                            isDiscount && "line-through mr-4"
                          }`}
                        >
                          {numberToRupiah(menu.price)}
                        </div>
                        <div>
                          {isDiscount && numberToRupiah(menu.discount_price)}
                        </div>
                      </>
                    }
                  />
                </Card>
              </Col>
            );
          })}
        </Row>
      ),
    };
  });

  return (
    <>
      <div className="hidden">
        {x}
        {y}
      </div>
      <Switch
        className="mb-2 "
        checkedChildren="Discount"
        checked={isDiscount}
        onChange={(checked) => {
          clearCart();
          setDiscount(checked);
        }}
      />
      {isDiscount && (
        <Alert
          className="my-2"
          banner
          message={
            <Marquee pauseOnHover gradient={false}>
              HARGA DISKON
            </Marquee>
          }
        />
      )}
      {isLoading ? (
        <p>loading...</p>
      ) : (
        <Tabs defaultActiveKey="1" type="card" items={tabList} />
      )}
    </>
  );
}
