import React from "react";

interface PrivateComponentProps {
  authorizedRole: string[];
  userRole: string;
  children: React.ReactNode;
}

const PrivateComponent: React.FC<PrivateComponentProps> = ({
  authorizedRole,
  userRole,
  children,
}) => {
  return authorizedRole.includes(userRole.toLowerCase()) ? (
    <>{children}</>
  ) : null;
};

export default PrivateComponent;
