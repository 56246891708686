export const normalizePrice = (value: string) => {
  if (!value) return value;

  const numericValue = value.replace(/\D/g, "");
  const numberValue = parseInt(numericValue, 10);

  if (isNaN(numberValue)) return "";

  const formattedValue = numberValue.toLocaleString("id-ID");

  return formattedValue;
};

export const numberToRupiah = (number: number) => {
  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  return formatter.format(number);
};

export function sanitizePrice(value: string): number {
  const sanitizedValue = value.replace(/[^\d]/g, "");
  return Number(sanitizedValue);
}
