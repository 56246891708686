import { DeleteOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input, Space, Typography } from "antd";
import { numberToRupiah } from "../../../utils/normalize";
import useCartStore, { CartItem } from "../../../store/cart";

const { Title, Text } = Typography;
export default function CartItems(props: CartItem) {
  const { decreaseQuantity, increaseQuantity, removeItem } = useCartStore(
    (state) => state
  );
  return (
    <div className="shadow-sm p-4 border-2 rounded-lg">
      <div className="flex justify-between">
        <div className="flex flex-col justify-between">
          <Title level={4} className="h-18">
            {props.name}
          </Title>
          <Text className="text-gray-400">{numberToRupiah(props.price)}</Text>
        </div>
        <div className="flex flex-col gap-4 justify-between">
          <div className="flex justify-end">
            <Button
              type="default"
              icon={<DeleteOutlined />}
              danger
              onClick={() => removeItem(props.id)}
              size="small"
            />
          </div>
          <Space.Compact>
            <Button
              type="primary"
              className="bg-blue-500"
              icon={<MinusOutlined />}
              onClick={() => decreaseQuantity(props.id)}
              size="small"
            />
            <Input
              value={props.quantity}
              className="w-10"
              readOnly
              size="small"
            />
            <Button
              type="primary"
              className="bg-blue-500"
              icon={<PlusOutlined />}
              onClick={() => increaseQuantity(props.id)}
              size="small"
            />
          </Space.Compact>
        </div>
      </div>
    </div>
  );
}
