import { Skeleton, Table, type TableProps } from "antd";
import type { ColumnsType } from "antd/es/table";

export interface CustomTableProps<T> extends TableProps<T> {
  isLoading?: boolean;
}

export interface DataKeys {
  [key: string]: string;
}

export interface TitleProps {
  props: {
    children: string;
  };
}

export default function CustomTable<T extends object>(
  props: CustomTableProps<T>
) {
  const { isLoading, columns } = props;

  const dataKeys: DataKeys = {};
  const loadingColumns: ColumnsType<T> = [];

  columns?.forEach((column) => {
    let key = (column?.title ?? "") as string | TitleProps;
    key = typeof key === "string" ? key : key?.props?.children;

    if (key) {
      key = key.toLowerCase().replace(/\s/g, "_");

      dataKeys[key] = "";
      loadingColumns.push({
        ...column,
        dataIndex: key,
        render: () => <Skeleton className="h-[20px]" />,
      });
    }
  });

  const skeletonArray = new Array(4).fill(dataKeys).map((data, index) => {
    return {
      ...data,
      key: index,
    };
  });
  return (
    <>
      {isLoading ? (
        <Table
          {...props}
          columns={loadingColumns}
          dataSource={skeletonArray}
        ></Table>
      ) : (
        <Table<T> {...props} />
      )}
    </>
  );
}
