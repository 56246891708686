import { TransactionProps } from "../types/global";
import axios from "./axiosInstance";

export const getPaymentMethod = async () => {
  const response = await axios.get("/v1/payment-method");
  return response.data;
};

export const InitiatePayment = async (payload: TransactionProps) => {
  const response = await axios.post("/v1/trx/initiate", payload);
  return response.data;
};

export const getInvoiceDetail = async (trxId: string) => {
  const response = await axios.get(`/v1/trx/invoice/${trxId}`);
  return response.data;
};

export const getTransactionList = async ({
  outletId,
  params,
}: {
  outletId: string;
  params: {
    page: number;
    limit: number;
    start_date: string;
    end_date: string;
  };
}) => {
  const outlet = outletId === "all" ? "" : outletId;
  const response = await axios.get(`/v1/trx/history/${outlet}`, { params });
  return response.data;
};

export const getSummaryTransaction = async ({
  outletId,
  params,
}: {
  outletId: string;
  params: {
    start_date: string;
    end_date: string;
  };
}) => {
  const outlet = outletId === "all" ? "" : outletId;
  const response = await axios.get(`/v1/trx/summary/${outlet}`, { params });
  return response.data;
};

export const getTotalPerItem = async ({
  outletId,
  params,
}: {
  outletId: string;
  params: {
    start_date: string;
    end_date: string;
  };
}) => {
  const outlet = outletId === "all" ? "" : outletId;
  const response = await axios.get(`/v1/trx/item/${outlet}`, {
    params,
  });
  return response.data;
};
