import { Alert, Button, Form, Input, message } from "antd";
import { useMutation } from "react-query";
import { login } from "../services/auth";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { local } from "../utils/localStorage";
import { profileStore } from "../store/profile";

interface ErrorMessage {
  message: string;
}

export default function LoginPage() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const {
    setOutletId,
    setOutletName,
    setRoles,
    setUserId,
    setUserName,
    setIsAuth,
  } = profileStore((state) => state);

  const { mutate, isLoading, isError } = useMutation(login, {
    onSuccess: (data) => {
      message.success(`Selamat datang, ${data.username}`);
      local.setItem("token", data.accessToken);

      setOutletId(data.outlet.outlet_id);
      setOutletName(data.outlet.name);
      setRoles(data.roles);
      setUserId(data.id);
      setUserName(data.username);
      setIsAuth(true);
      navigate("/");
    },
    onError: (error: AxiosError<ErrorMessage>) => {
      setErrorMessage(error?.response?.data?.message ?? "Unknown error");
    },
  });

  const inputValues = Form.useWatch([], form);

  const onFinish = (values: { username: string; password: string }) => {
    mutate(values);
  };

  useEffect(() => {
    if (local.getItem("token") !== null) navigate("/");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="lg:w-1/4 md:w-2/3 w-5/6">
        <div className="border px-4 py-8 rounded-xl bg-white shadow-sm">
          <h1 className="text-2xl font-bold text-center mb-4">Login</h1>
          {isError && (
            <Alert
              type="error"
              message={errorMessage}
              className="mb-4"
              showIcon
            />
          )}
          <Form
            layout="vertical"
            requiredMark={false}
            form={form}
            onFinish={onFinish}
          >
            <Form.Item label="Username" required name="username">
              <Input type="text" />
            </Form.Item>
            <Form.Item label="Password" required name="password">
              <Input type="Password" />
            </Form.Item>

            <Button
              type="primary"
              block
              className="bg-blue-500"
              htmlType="submit"
              disabled={!inputValues?.username || !inputValues?.password}
              loading={isLoading}
            >
              Login
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}
