import { PrinterOutlined } from "@ant-design/icons";
import { Button, Result, Spin, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Invoice from "../components/Invoice";
import { useEffect, useState, useRef } from "react";
import { useQuery } from "react-query";
import { getInvoiceDetail } from "../services/transactions";
import { InvoiceDetailProps } from "../types/global";
import ReactToPrint from "react-to-print";

const PrintBtn = () => <Button icon={<PrinterOutlined />}>Cetak Struk</Button>;

export default function ResultPage() {
  const navigate = useNavigate();
  const { trxId } = useParams();
  const [invoice, setInvoice] = useState<InvoiceDetailProps>();

  useEffect(() => {
    if (!trxId) navigate("/");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoading } = useQuery({
    queryKey: ["getInvoiceDetail", trxId],
    queryFn: () => getInvoiceDetail(trxId ?? ""),
    enabled: !!trxId,
    onSuccess: (data) => {
      setInvoice(data?.data);
    },
    onError: () => {
      message.error("Invoice tidak ditemukan");
      navigate("/");
    },
  });

  const componentRef = useRef<HTMLDivElement>(null);

  return (
    <Result
      status="success"
      title={<div>Transaksi Berhasil</div>}
      subTitle={
        <>
          <p className="my-4">
            Pastikan OrderId {invoice?.transaction_id} sudah tersimpan di
            reporting
          </p>
          <Spin spinning={isLoading}>
            <div ref={componentRef}>
              <Invoice data={invoice} />
            </div>
          </Spin>
        </>
      }
      extra={[
        <Button
          type="primary"
          key="back"
          className="bg-blue-500"
          onClick={() => navigate("/")}
        >
          Kembali
        </Button>,

        <ReactToPrint
          key="print"
          trigger={PrintBtn}
          content={() => componentRef.current}
        />,
      ]}
    />
  );
}
