import { Routes, Route, useNavigate } from "react-router-dom";
import "./App.css";
import LayoutDashboard from "./components/Layout";
import MainDashboard from "./pages/MainDashboard";
import MenuDashboard from "./pages/MenuDashboard";
import ReportingDashboard from "./pages/ReportingDashboard";
import LoginPage from "./pages/LoginPage";
import dayjs from "dayjs";
import "dayjs/locale/id";
import ResultPage from "./pages/ResultPage";
import { Button, Result } from "antd";
import PrivateRoute from "./components/Global/PrivateRoute";

dayjs.locale("id");
function App() {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route path="/" element={<LayoutDashboard />}>
        <Route
          index
          element={
            <PrivateRoute
              authorizedRoles="pegawai"
              component={<MainDashboard />}
            />
          }
        />
        <Route
          path="menu-management"
          element={
            <PrivateRoute
              authorizedRoles="admin"
              component={<MenuDashboard />}
            />
          }
        />
        <Route path="reporting" element={<ReportingDashboard />} />
        <Route path="result/:trxId" element={<ResultPage />} />
        <Route
          path="*"
          element={
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
              extra={
                <Button
                  type="primary"
                  className="bg-blue-500"
                  onClick={() => navigate("/")}
                >
                  Back Home
                </Button>
              }
            />
          }
        />
      </Route>
      <Route path="login" element={<LoginPage />} />
    </Routes>
  );
}

export default App;
