import React, { useEffect, useState } from "react";
import {
  AreaChartOutlined,
  ContainerOutlined,
  DollarOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button, Dropdown, Spin, Drawer } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { profileStore } from "../../store/profile";
import { local } from "../../utils/localStorage";
import jwtDecode from "jwt-decode";

const { Header, Content } = Layout;
const LayoutDashboard: React.FC = () => {
  const [validLogin, setValidLogin] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();

  const menuNavigate = (key: string) => {
    const index = Number(key) - 1;
    type NavRoutes = { [key: number]: string };

    const nav: NavRoutes = {
      0: "/",
      1: "/menu-management",
      2: "/reporting",
    };

    navigate(nav[index]);
  };

  const { outletName, roles } = profileStore((state) => state);

  const handleLogOut = () => {
    local.removeItem("token");
    local.removeItem("user-profile");
    navigate("/login");
  };

  const validateLoginToken = () => {
    const token = local.getItem("token");
    if (!token) return handleLogOut();

    try {
      const decodedToken: { exp: number } = jwtDecode(token);
      const tokenExpireDate = new Date(decodedToken.exp * 1000);
      const currentDate = new Date();

      if (tokenExpireDate <= currentDate) handleLogOut();

      setValidLogin(true);
    } catch (error) {
      handleLogOut();
    }
  };

  useEffect(() => {
    validateLoginToken();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!validLogin) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spin size="large" />
      </div>
    );
  }

  const isShowLayout = !location.pathname.startsWith("/result");
  const adminRoutes = [
    {
      key: "1",
      icon: <DollarOutlined />,
      label: "Transaksi",
    },
    {
      key: "2",
      icon: <ContainerOutlined />,
      label: "Menu",
    },
    {
      key: "3",
      icon: <AreaChartOutlined />,
      label: "Reporting",
    },
  ];

  const getRoutes = () => {
    if (roles === "admin") return adminRoutes.splice(1, 2);
    if (roles === "pegawai")
      return adminRoutes.slice(0, 1).concat(adminRoutes.slice(2));
    if (roles === "investor") return adminRoutes.splice(2, 2);
  };

  return (
    <Layout className="min-h-screen">
      <Drawer
        width={300}
        placement="left"
        onClose={() => setCollapsed(!collapsed)}
        open={collapsed}
      >
        <div className="flex justify-evenly mb-6">
          <img
            src="/OIG.jpeg"
            alt="logo"
            className="w-1/2 rounded-2xl border-[#15415e] border-2"
          />
          <p className="font-bold text-[#15415e] text-4xl my-8">
            POS
            <span className="block text-xs font-normal leading-4">
              POINT OF SALE
            </span>
          </p>
        </div>
        <Menu
          className="!border-none"
          onClick={(e) => menuNavigate(e.key)}
          defaultSelectedKeys={["1"]}
          items={getRoutes()}
        />
      </Drawer>

      <Layout>
        {isShowLayout && (
          <Header style={{ padding: 0, background: "#fff" }}>
            <div className="flex justify-between items-center">
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
              <Dropdown
                placement="bottomRight"
                arrow
                className="mr-4"
                trigger={["click"]}
                menu={{
                  items: [
                    {
                      key: "1",
                      label: "Logout",
                      onClick: handleLogOut,
                    },
                  ],
                }}
              >
                <Button type="primary" className="bg-blue-500">
                  {outletName}
                </Button>
              </Dropdown>
            </div>
          </Header>
        )}
        <Content
          style={{
            padding: 16,
            background: "#fff",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutDashboard;
